.inputfile {
	width: 0.1px;
	height: 0.1px;
	opacity: 0;
	overflow: hidden;
	position: absolute;
	z-index: -1;
}
.inputfile + label {
    font-size: 1.25em;
    font-weight: 700;
    color: white;
    background-color: black;
    display: inline-block;
}

/* input[type="date"]::-webkit-clear-button {
    display: none;
} */

.PhoneInputCountry{
	background-color: #474548;
}
.PhoneInput{
	background-color: #474548;
}

.rdt_TableCell div{
	white-space: normal !important;
}

.rdt_TableHead div{
	white-space: normal !important;
}

.rdt_TableBody .rdt_TableRow:nth-child(odd){
	background: rgba(192, 192, 192, 0.05);;
}

.sc-dmctIk.kgfIBl{
	max-height: none;
}
